<template>
  <div>
    <a-card :border="false">

        <a-table
          :rowKey="(record) => record.dealerId"
          :columns="columnsDeatil"
          :data-source="tableDeatilData"
          bordered
          :pagination="false"
          @change="changeTable"
        >
          <span slot="surplusPreOrderCount" slot-scope="text, row">
            <a @click="checkQuotaLog(row,1)">{{row.surplusPreOrderCount}}</a>
          </span>
          <span slot="temporaryRemainingQuota" slot-scope="text, row">
            <a @click="checkQuotaLog(row,2)">{{row.temporaryRemainingQuota}}</a>
          </span>
          <span slot="orderCount" slot-scope="text, row">
            {{row.orderCount + row.temporaryQuota}} （{{row.orderCount}}/{{row.temporaryQuota}}）
          </span>

          <span slot="shenyu" slot-scope="text, row">
            {{(row.orderCount + row.temporaryQuota) - row.total}}
          </span>
        </a-table>

      <a-modal title="查看配额使用日志" :maskClosable="false" v-model="visible" width="50%" :footer="null">
        <a-table
          :rowKey="(record) => record.id"
          :columns="columnsCheck"
          :data-source="tableDataCheck"
          bordered
          :pagination="false"
        >
        <span slot="quotaType" slot-scope="text">
          <span v-if="text == 1">固定</span>
          <span v-if="text == 2">临时</span>
        </span>
          <span slot="quotaSpendingStatus" slot-scope="text">
          <span v-if="text == 1">支出</span>
          <span v-if="text == 2">收入</span>
          <span v-if="text == 3">系统增额</span>
          <span v-if="text == 4">系统减额</span>
        </span>

        </a-table>
      </a-modal>

    </a-card>
  </div>
</template>

<script>
import { columns } from './components/colums.js'
import DealerPreOrdersNumEditModal from './components/DealerPreOrdersNumEditModal.vue'
import AllocationRuota from './components/AllocationRuota.vue'

import { delDealerPreOrdersNum, listDealerPreOrdersNum } from './api/DealerPreOrdersNumApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerPreOrdersNum',
  components: {
    DealerPreOrdersNumEditModal,
    AllocationRuota,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      columnsDeatil: [
            {
              title: '预订单总数',
              dataIndex: 'total',
              key: 'total',
              /*width:150,*/
              align: 'center'
            },
            {
              title: '首次申请总数',
              dataIndex: 'firstTotal',
              key: 'firstTotal',
              /*width:150,*/
              align: 'center'
            },
            {
              title: '二次申请总数',
              dataIndex: 'secondTotal',
              key: 'secondTotal',
              /*width:150,*/
              align: 'center'
            },
            {
              title: '配额 （固定/临时）',
              dataIndex: 'orderCount',
              key: 'orderCount',
              /*width:150,*/
              align: 'center',
              scopedSlots: { customRender: 'orderCount'}
            },
             {
              title: '固定剩余可分配配额',
              dataIndex: 'surplusPreOrderCount',
              key: 'surplusPreOrderCount',
              /*width:150,*/
              align: 'center',
              scopedSlots: { customRender: 'surplusPreOrderCount'}
            },
            // {
            //   title: '临时配额',
            //   dataIndex: 'temporaryQuota',
            //   key: 'temporaryQuota',
            //   /*width:150,*/
            //   align: 'center'
            // },
            {
              title: '临时剩余可分配配额',
              dataIndex: 'temporaryRemainingQuota',
              key: 'temporaryRemainingQuota',
              /*width:150,*/
              align: 'center',
              scopedSlots: { customRender: 'temporaryRemainingQuota'}
            },
            {
              title: '剩余可报备配额',
              dataIndex: 'baobeiCount',
              key: 'baobeiCount',
              /*width:150,*/
              align: 'center'
            },
      ],
      tableDeatilData: [],
      isSubmit: checkPermission('dealer:quota:submit'),
      isApply: checkPermission('dealer:quota:apply'),
      visible: false,
      tableDataCheck:[],
      columnsCheck: [
        // {
        //   title: '配额类型',
        //   dataIndex: 'quotaType',
        //   key: 'quotaType',
        //   /*width:150,*/
        //   align: 'center',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'quotaType' },
        // },
        {
          title: '配额收支类型',
          dataIndex: 'quotaSpendingStatus',
          key: 'quotaSpendingStatus',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'quotaSpendingStatus' },
        },
        {
          title: '日志',
          dataIndex: 'reason',
          key: 'reason',
          width:350,
          align: 'center',
          // ellipsis: true,
        },
        // {
        //   title: '配额数',
        //   dataIndex: 'num',
        //   key: 'num',
        //   /*width:150,*/
        //   align: 'center',
        //   ellipsis: true,
        // },
        {
          title: '创建人',
          dataIndex: 'createUser',
          key: 'createUser',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '操作时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width:180,
          align: 'center',
          ellipsis: true,
        },
      ],
    }
  },

  methods: {
    // 查看配额使用日志
    checkQuotaLog(row,type) {
      console.log(row)
      this.visible = true
      let params = {
        dealerId: row.dealerId,
        quotaType: type
      }
      this.axios.get('/api/base/quota/dealerQuotaLog/allQuotaLog', { params: params }).then((res) => {
        console.log("res",res.body)
        if(res.body){
          this.tableDataCheck=res.body
        }

        console.log("res",this.tableDataCheck)
      })
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listDealerPreOrdersNum({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        dealerId: this.$store.getters.userInfo.dealerId,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
            this.getDetail()
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.pageSize = 10
      this.page.current = 1
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
     // 配额详情
    getDetail(){
      this.axios.get('/api/base/quota/dealerPreOrdersNum/selectByDealer').then(res=>{

      if(res.body){
       this.tableDeatilData = [res.body]
      }else{
        this.tableDeatilData = []
      }
      console.log(this.tableDeatilData)
    })
    }
  },
  created() {

    this.getDetail()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
